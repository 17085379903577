import { CognitoUserPool } from "amazon-cognito-identity-js";

const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOl_ID;
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

console.log("env variables", process.env);
console.log("USER_POOL_ID", USER_POOL_ID);
console.log("CLIENT_ID ", CLIENT_ID);
console.log(
  "REACT_APP_COGNITO_USER_POOl_ID2",
  process.env.REACT_APP_COGNITO_USER_POOl_ID2
);

const poolData = {
  UserPoolId: "us-east-1_Y4hlmfaxH",
  ClientId: "671h04cvlj62o2bdtnr67kbb4c",
};

export default new CognitoUserPool(poolData);

// import { CognitoUserPool } from "amazon-cognito-identity-js";

// const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOl_ID;
// const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

// REACT_APP_COGNITO_USER_POOl_ID
// REACT_APP_COGNITO_CLIENT_ID

// const poolData = {
//   UserPoolId: USER_POOL_ID,
//   ClientId: CLIENT_ID,
// };

// export default new CognitoUserPool(poolData);
