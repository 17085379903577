import { Form } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { CardViewer } from "../features/cardViewer/CardViewer";
import { Collection } from "../features/collection/Collection";

import { dalleTest } from "../components/api/dalleHelper";

import { dynFetchAllCards } from "../helpers/apiHelpers/dynamoHelpers";
import { getS3Item } from "../helpers/apiHelpers/s3Helpers";

import { Card } from "../components/Card/card.js";

import { useQuery } from "react-query";
import { fetchAllGnomes } from "../helpers/reactQueryHelpers/fetchAllGnomes";
import axios from "axios";

export default function CollectionView() {
  const { isLoading, data, isError, error } = useQuery(
    "card-collection",
    fetchAllGnomes
  );

  if (isLoading) {
    return <h2>LOADING...</h2>;
  }

  if (isError) {
    return <h2>error {error.message}</h2>;
  }

  return (
    <>
      <h2>card collection</h2>
      {/* {console.log("data", data?.data?.data?.Items)} */}

      {data?.data?.data?.Items.map((item) => {
        return <Card key={item?.CardID} card={item} />;
      })}
    </>
  );
}
