import { createSlice } from "@reduxjs/toolkit";
import { draw1 } from "../../helpers/cardHelpers/deckGenerator";

export const playerSlice = createSlice({
  name: "players",
  initialState: {
    players: {},
    currentPlayerTurn: 0,
    numberOfPlayers: 1,
  },
  reducers: {
    progressTurn: (state) => {
      if (state.currentPlayerTurn <= state.numberOfPlayers) {
        state.currentPlayerTurn++;
      } else {
        state.currentPlayerTurn = 0;
      }
    },
    setUpGame: (state, action) => {
      let x = 1;
      state.numberOfPlayers = x;
      for (let i = 0; i <= x; i++) {
        state.players[i] = { player: i, hand: [] };
      }
    },
    currentPlayerDraw1: (state, action) => {
      state.players[state.currentPlayerTurn].hand.push(draw1());
    },
  },
});

// Action creators are generated for each case reducer function
export const { progressTurn, setUpGame, currentPlayerDraw1 } =
  playerSlice.actions;

export default playerSlice.reducer;
