import React, { useState } from "react";

import UserPool from "./UserPool";

function SignUpPanel(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();

    UserPool.signUp(
      email,
      password,
      [
        {
          Name: "email",
          Value: email,
        },
        // {
        //   Name: "name",
        //   Value: name,
        // },
      ],
      null,
      (err, data) => {
        if (err) {
          console.log("signup error", err);
        }
        console.log("signup success", data);
      }
    );
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        ></input>
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        ></input>

        <button type="submit">Signup</button>
      </form>
    </div>
  );
}

export default SignUpPanel;
