import { Form } from "react-router-dom";
import {
  draw1,
  drawFullShuffledDeck,
} from "../helpers/cardHelpers/deckGenerator";

import { useSelector, useDispatch } from "react-redux";

import { PokerHand } from "../features/pokerHands/PokerHand";
import { PlayerDisplay } from "../features/players/PlayerDisplay";

// import { Deck } from "../features/deck/Deck";
import { Deck } from "../features/gameState/Deck";
import { PlayerHand } from "../features/gameState/PlayerHand";

export default function GameTesting() {
  // const players = useSelector((state) => state.game?.players);
  const players = useSelector((state) => {
    return state.game?.players;
  });

  const playerHands = Object.keys(players).map((key, i) => {
    return <PlayerHand player={i} key={i} />;
  });

  return (
    <div id="gametesting">
      <div>Game Testing</div>

      <div id="gametestingframe">
        <Deck />
      </div>
      {/* <div id="gametestingframe">
        <PlayerHand player={0} />
      </div> */}

      <div id="gametestingframe">{playerHands}</div>
    </div>
  );
}
