import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import "./loginPanel.css";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

// import { Link } from "react-router-dom";

// import { useHistory } from "react-router-dom";

function LoginPanel(props) {
  //   let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { authenticate } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();
    console.log("on login submit");
    authenticate(email, password)
      .then((data) => {
        // history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log("on login submit failure:", err);
      });
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="login-panel">
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        ></input>
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        ></input>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPanel;
