import axios from "axios";

import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../authentication/Account";

import { useQuery } from "react-query";

import { initGnome } from "../helpers/apiHelpers/initGnome";
import { generateImagesForGnome } from "../helpers/apiHelpers/generateImagesForGnome";

import { createStats } from "../helpers/apiHelpers/createStats";
import { getGnomeFromDDB } from "../helpers/apiHelpers/testAPI.js";
import { CardImageSelection } from "../components/CardImageSelection/cardImageSelection";

import { Card } from "../components/Card/card.js";
import { StatCard } from "../components/StatCard/statCard.js";
import { fetchAllGnomes } from "../helpers/reactQueryHelpers/fetchAllGnomes";

export default function CardShop() {
  const { getSession } = useContext(AccountContext);
  // const [session, setSession] = useState(null);

  const [email, setEmail] = useState("");

  useEffect(() => {
    getSession()
      .then((session) => {
        // console.log("WHAT DOES SESSION LOOK LIKE", session);
        setEmail(session?.idToken?.payload?.email);
      })
      .catch((err) => {
        console.log("session error:", err);
      });
  }, []);

  const { isLoading, data, isError, error } = useQuery(
    "card-collection",
    fetchAllGnomes
  );

  if (isLoading) {
    return <h2>LOADING...</h2>;
  }

  if (isError) {
    return <h2>error {error.message}</h2>;
  }

  const createGnome = () => {
    console.log("initGnome test");
    initGnome(email).then((res) => {
      console.log("initGnome res", res);
    });
  };

  return (
    <div id="gametesting">
      <div>CARD SHOP</div>
      <div>Welcome to the card shop</div>

      <button aria-label="gpt request" onClick={createGnome}>
        createGnome test
      </button>
      <h2>new gnomes</h2>
      {console.log("data", data?.data?.data?.Items)}

      {data?.data?.data?.Items.map((gnome) => {
        if (gnome?.gnomeGenerationStage === "1_INIT_GNOME")
          return (
            <div key={gnome?.CardID}>
              <div> {gnome?.name}</div>
              <button
                aria-label="gpt request"
                onClick={(e) => {
                  generateImagesForGnome(gnome?.CardID);
                }}
              >
                generate images
              </button>
            </div>
          );
      })}

      <h2>select an image</h2>
      {data?.data?.data?.Items.map((gnome) => {
        if (gnome?.gnomeGenerationStage === "2_IMAGES_CREATED")
          return (
            <div key={gnome?.CardID}>
              <CardImageSelection card={gnome} />
            </div>
          );
      })}
      <h2>generate power and stats</h2>
      {data?.data?.data?.Items.map((gnome) => {
        if (gnome?.gnomeGenerationStage === "3_MAIN_IMAGE_SELECTED")
          return (
            <div key={gnome?.CardID}>
              <Card card={gnome} />
              <button
                aria-label="gpt request"
                onClick={(e) => {
                  createStats(gnome?.CardID);
                }}
              >
                create stats
              </button>
            </div>
          );
      })}

      <h2>finished send to collection</h2>
      {data?.data?.data?.Items.map((gnome) => {
        if (gnome?.gnomeGenerationStage === "4_MECHANICS_CREATED")
          return (
            <div key={gnome?.CardID}>
              <StatCard card={gnome} />

              <button
                aria-label="gpt request"
                onClick={(e) => {
                  createStats(gnome?.CardID);
                }}
              >
                create stats
              </button>
            </div>
          );
      })}
    </div>
  );
}
