export const minorArcana = {
  1: { uniqueId: 1, suit: "COINS", valueText: "Ace", value: 1, code: "1CO" },
  2: { uniqueId: 2, suit: "COINS", valueText: "Two", value: 2, code: "2CO" },
  3: { uniqueId: 3, suit: "COINS", valueText: "Three", value: 3, code: "3CO" },
  4: { uniqueId: 4, suit: "COINS", valueText: "Four", value: 4, code: "4CO" },
  5: { uniqueId: 5, suit: "COINS", valueText: "Five", value: 5, code: "5CO" },
  6: { uniqueId: 6, suit: "COINS", valueText: "Six", value: 6, code: "6CO" },
  7: { uniqueId: 7, suit: "COINS", valueText: "Seven", value: 7, code: "7CO" },
  8: { uniqueId: 8, suit: "COINS", valueText: "Eight", value: 8, code: "8CO" },
  9: { uniqueId: 9, suit: "COINS", valueText: "Nine", value: 9, code: "9CO" },
  10: {
    uniqueId: 10,
    suit: "COINS",
    valueText: "Ten",
    value: 10,
    code: "10CO",
  },
  11: {
    uniqueId: 11,
    suit: "COINS",
    valueText: "Page",
    value: 11,
    code: "11CO",
  },
  12: {
    uniqueId: 12,
    suit: "COINS",
    valueText: "Knight",
    value: 12,
    code: "12CO",
  },
  13: {
    uniqueId: 13,
    suit: "COINS",
    valueText: "Queen",
    value: 13,
    code: "13CO",
  },
  14: {
    uniqueId: 14,
    suit: "COINS",
    valueText: "King",
    value: 14,
    code: "14CO",
  },
  15: { uniqueId: 15, suit: "STAVES", valueText: "Ace", value: 1, code: "1ST" },
  16: { uniqueId: 16, suit: "STAVES", valueText: "Two", value: 2, code: "2ST" },
  17: {
    uniqueId: 17,
    suit: "STAVES",
    valueText: "Three",
    value: 3,
    code: "3ST",
  },
  18: {
    uniqueId: 18,
    suit: "STAVES",
    valueText: "Four",
    value: 4,
    code: "4ST",
  },
  19: {
    uniqueId: 19,
    suit: "STAVES",
    valueText: "Five",
    value: 5,
    code: "5ST",
  },
  20: { uniqueId: 20, suit: "STAVES", valueText: "Six", value: 6, code: "6ST" },
  21: {
    uniqueId: 21,
    suit: "STAVES",
    valueText: "Seven",
    value: 7,
    code: "7ST",
  },
  22: {
    uniqueId: 22,
    suit: "STAVES",
    valueText: "Eight",
    value: 8,
    code: "8ST",
  },
  23: {
    uniqueId: 23,
    suit: "STAVES",
    valueText: "Nine",
    value: 9,
    code: "9ST",
  },
  24: {
    uniqueId: 24,
    suit: "STAVES",
    valueText: "Ten",
    value: 10,
    code: "10ST",
  },
  25: {
    uniqueId: 25,
    suit: "STAVES",
    valueText: "Page",
    value: 11,
    code: "11ST",
  },
  26: {
    uniqueId: 26,
    suit: "STAVES",
    valueText: "Knight",
    value: 12,
    code: "12ST",
  },
  27: {
    uniqueId: 27,
    suit: "STAVES",
    valueText: "Queen",
    value: 13,
    code: "13ST",
  },
  28: {
    uniqueId: 28,
    suit: "STAVES",
    valueText: "King",
    value: 14,
    code: "14ST",
  },
  29: { uniqueId: 29, suit: "CUPS", valueText: "Ace", value: 1, code: "1CU" },
  30: { uniqueId: 30, suit: "CUPS", valueText: "Two", value: 2, code: "2CU" },
  31: { uniqueId: 31, suit: "CUPS", valueText: "Three", value: 3, code: "3CU" },
  32: { uniqueId: 32, suit: "CUPS", valueText: "Four", value: 4, code: "4CU" },
  33: { uniqueId: 33, suit: "CUPS", valueText: "Five", value: 5, code: "5CU" },
  34: { uniqueId: 34, suit: "CUPS", valueText: "Six", value: 6, code: "6CU" },
  35: { uniqueId: 35, suit: "CUPS", valueText: "Seven", value: 7, code: "7CU" },
  36: { uniqueId: 36, suit: "CUPS", valueText: "Eight", value: 8, code: "8CU" },
  37: { uniqueId: 37, suit: "CUPS", valueText: "Nine", value: 9, code: "9CU" },
  38: { uniqueId: 38, suit: "CUPS", valueText: "Ten", value: 10, code: "10CU" },
  39: {
    uniqueId: 39,
    suit: "CUPS",
    valueText: "Page",
    value: 11,
    code: "11CU",
  },
  40: {
    uniqueId: 40,
    suit: "CUPS",
    valueText: "Knight",
    value: 12,
    code: "12CU",
  },
  41: {
    uniqueId: 41,
    suit: "CUPS",
    valueText: "Queen",
    value: 13,
    code: "13CU",
  },
  42: {
    uniqueId: 42,
    suit: "CUPS",
    valueText: "King",
    value: 14,
    code: "14CU",
  },
  43: { uniqueId: 43, suit: "SWORDS", valueText: "Ace", value: 1, code: "1SW" },
  44: { uniqueId: 44, suit: "SWORDS", valueText: "Two", value: 2, code: "2SW" },
  45: {
    uniqueId: 45,
    suit: "SWORDS",
    valueText: "Three",
    value: 3,
    code: "3SW",
  },
  46: {
    uniqueId: 46,
    suit: "SWORDS",
    valueText: "Four",
    value: 4,
    code: "4SW",
  },
  47: {
    uniqueId: 47,
    suit: "SWORDS",
    valueText: "Five",
    value: 5,
    code: "5SW",
  },
  48: { uniqueId: 48, suit: "SWORDS", valueText: "Six", value: 6, code: "6SW" },
  49: {
    uniqueId: 49,
    suit: "SWORDS",
    valueText: "Seven",
    value: 7,
    code: "7SW",
  },
  50: {
    uniqueId: 50,
    suit: "SWORDS",
    valueText: "Eight",
    value: 8,
    code: "8SW",
  },
  51: {
    uniqueId: 51,
    suit: "SWORDS",
    valueText: "Nine",
    value: 9,
    code: "9SW",
  },
  52: {
    uniqueId: 52,
    suit: "SWORDS",
    valueText: "Ten",
    value: 10,
    code: "10SW",
  },
  53: {
    uniqueId: 53,
    suit: "SWORDS",
    valueText: "Page",
    value: 11,
    code: "11SW",
  },
  54: {
    uniqueId: 54,
    suit: "SWORDS",
    valueText: "Knight",
    value: 12,
    code: "12SW",
  },
  55: {
    uniqueId: 55,
    suit: "SWORDS",
    valueText: "Queen",
    value: 13,
    code: "13SW",
  },
  56: {
    uniqueId: 56,
    suit: "SWORDS",
    valueText: "King",
    value: 14,
    code: "14SW",
  },
};

const majorArcana = {
  57: {
    uniqueId: 57,
    suit: "MAJOR",
    valueText: "The Fool",
    name: "The Fool",
    value: 0,
    numeral: "0",
    code: "0MAJOR",
  },
  58: {
    uniqueId: 58,
    suit: "MAJOR",
    valueText: "The Magician",
    name: "The Magician",
    value: 1,
    numeral: "I",
    code: "1MAJOR",
  },
  59: {
    uniqueId: 59,
    suit: "MAJOR",
    valueText: "The High Priestess",
    name: "The High Priestess",
    value: 2,
    numeral: "II",
    code: "2MAJOR",
  },
  60: {
    uniqueId: 60,
    suit: "MAJOR",
    valueText: "The Empress",
    name: "The Empress",
    value: 3,
    numeral: "III",
    code: "3MAJOR",
  },
  61: {
    uniqueId: 61,
    suit: "MAJOR",
    valueText: "The Emperor",
    name: "The Emperor",
    value: 4,
    numeral: "IV",
    code: "4MAJOR",
  },
  62: {
    uniqueId: 62,
    suit: "MAJOR",
    valueText: "The Hierophant",
    name: "The Hierophant",
    value: 5,
    numeral: "V",
    code: "5MAJOR",
  },
  63: {
    uniqueId: 63,
    suit: "MAJOR",
    valueText: "The Lovers",
    name: "The Lovers",
    value: 6,
    numeral: "VI",
    code: "6MAJOR",
  },
  64: {
    uniqueId: 64,
    suit: "MAJOR",
    valueText: "The Chariot",
    name: "The Chariot",
    value: 7,
    numeral: "VII",
    code: "7MAJOR",
  },
  65: {
    uniqueId: 65,
    suit: "MAJOR",
    valueText: "Strength",
    name: "Strength",
    value: 8,
    numeral: "VIII",
    code: "8MAJOR",
  },
  66: {
    uniqueId: 66,
    suit: "MAJOR",
    valueText: "The Hermit",
    name: "The Hermit",
    value: 9,
    numeral: "IX",
    code: "9MAJOR",
  },
  67: {
    uniqueId: 67,
    suit: "MAJOR",
    valueText: "Wheel of Fortune",
    name: "Wheel of Fortune",
    value: 10,
    numeral: "X",
    code: "10MAJOR",
  },
  68: {
    uniqueId: 68,
    suit: "MAJOR",
    valueText: "Justice",
    name: "Justice",
    value: 11,
    numeral: "XI",
    code: "11MAJOR",
  },
  69: {
    uniqueId: 69,
    suit: "MAJOR",
    valueText: "The Hanged Man",
    name: "The Hanged Man",
    value: 12,
    numeral: "XII",
    code: "12MAJOR",
  },
  70: {
    uniqueId: 70,
    suit: "MAJOR",
    valueText: "Death",
    name: "Death",
    value: 13,
    numeral: "XIII",
    code: "13MAJOR",
  },
  71: {
    uniqueId: 71,
    suit: "MAJOR",
    valueText: "Temperance",
    name: "Temperance",
    value: 14,
    numeral: "XIV",
    code: "14MAJOR",
  },
  72: {
    uniqueId: 72,
    suit: "MAJOR",
    valueText: "The Devil",
    name: "The Devil",
    value: 15,
    numeral: "XV",
    code: "15MAJOR",
  },
  73: {
    uniqueId: 73,
    suit: "MAJOR",
    valueText: "The Tower",
    name: "The Tower",
    value: 16,
    numeral: "XVI",
    code: "16MAJOR",
  },
  74: {
    uniqueId: 74,
    suit: "MAJOR",
    valueText: "The Star",
    name: "The Star",
    value: 17,
    numeral: "XVII",
    code: "17MAJOR",
  },
  75: {
    uniqueId: 75,
    suit: "MAJOR",
    valueText: "The Moon",
    name: "The Moon",
    value: 18,
    numeral: "XVIII",
    code: "18MAJOR",
  },
  76: {
    uniqueId: 76,
    suit: "MAJOR",
    valueText: "The Sun",
    name: "The Sun",
    value: 19,
    numeral: "XIX",
    code: "19MAJOR",
  },
  77: {
    uniqueId: 77,
    suit: "MAJOR",
    valueText: "Judgement",
    name: "Judgement",
    value: 20,
    numeral: "XX",
    code: "20MAJOR",
  },
  78: {
    uniqueId: 78,
    suit: "MAJOR",
    valueText: "The World",
    name: "The World",
    value: 21,
    numeral: "XXI",
    code: "21MAJOR",
  },
};

export const allCards = {
  1: { uniqueId: 1, suit: "COINS", valueText: "Ace", value: 1, code: "1CO" },
  2: { uniqueId: 2, suit: "COINS", valueText: "Two", value: 2, code: "2CO" },
  3: { uniqueId: 3, suit: "COINS", valueText: "Three", value: 3, code: "3CO" },
  4: { uniqueId: 4, suit: "COINS", valueText: "Four", value: 4, code: "4CO" },
  5: { uniqueId: 5, suit: "COINS", valueText: "Five", value: 5, code: "5CO" },
  6: { uniqueId: 6, suit: "COINS", valueText: "Six", value: 6, code: "6CO" },
  7: { uniqueId: 7, suit: "COINS", valueText: "Seven", value: 7, code: "7CO" },
  8: { uniqueId: 8, suit: "COINS", valueText: "Eight", value: 8, code: "8CO" },
  9: { uniqueId: 9, suit: "COINS", valueText: "Nine", value: 9, code: "9CO" },
  10: {
    uniqueId: 10,
    suit: "COINS",
    valueText: "Ten",
    value: 10,
    code: "10CO",
  },
  11: {
    uniqueId: 11,
    suit: "COINS",
    valueText: "Page",
    value: 11,
    code: "11CO",
  },
  12: {
    uniqueId: 12,
    suit: "COINS",
    valueText: "Knight",
    value: 12,
    code: "12CO",
  },
  13: {
    uniqueId: 13,
    suit: "COINS",
    valueText: "Queen",
    value: 13,
    code: "13CO",
  },
  14: {
    uniqueId: 14,
    suit: "COINS",
    valueText: "King",
    value: 14,
    code: "14CO",
  },
  15: { uniqueId: 15, suit: "STAVES", valueText: "Ace", value: 1, code: "1ST" },
  16: { uniqueId: 16, suit: "STAVES", valueText: "Two", value: 2, code: "2ST" },
  17: {
    uniqueId: 17,
    suit: "STAVES",
    valueText: "Three",
    value: 3,
    code: "3ST",
  },
  18: {
    uniqueId: 18,
    suit: "STAVES",
    valueText: "Four",
    value: 4,
    code: "4ST",
  },
  19: {
    uniqueId: 19,
    suit: "STAVES",
    valueText: "Five",
    value: 5,
    code: "5ST",
  },
  20: { uniqueId: 20, suit: "STAVES", valueText: "Six", value: 6, code: "6ST" },
  21: {
    uniqueId: 21,
    suit: "STAVES",
    valueText: "Seven",
    value: 7,
    code: "7ST",
  },
  22: {
    uniqueId: 22,
    suit: "STAVES",
    valueText: "Eight",
    value: 8,
    code: "8ST",
  },
  23: {
    uniqueId: 23,
    suit: "STAVES",
    valueText: "Nine",
    value: 9,
    code: "9ST",
  },
  24: {
    uniqueId: 24,
    suit: "STAVES",
    valueText: "Ten",
    value: 10,
    code: "10ST",
  },
  25: {
    uniqueId: 25,
    suit: "STAVES",
    valueText: "Page",
    value: 11,
    code: "11ST",
  },
  26: {
    uniqueId: 26,
    suit: "STAVES",
    valueText: "Knight",
    value: 12,
    code: "12ST",
  },
  27: {
    uniqueId: 27,
    suit: "STAVES",
    valueText: "Queen",
    value: 13,
    code: "13ST",
  },
  28: {
    uniqueId: 28,
    suit: "STAVES",
    valueText: "King",
    value: 14,
    code: "14ST",
  },
  29: { uniqueId: 29, suit: "CUPS", valueText: "Ace", value: 1, code: "1CU" },
  30: { uniqueId: 30, suit: "CUPS", valueText: "Two", value: 2, code: "2CU" },
  31: { uniqueId: 31, suit: "CUPS", valueText: "Three", value: 3, code: "3CU" },
  32: { uniqueId: 32, suit: "CUPS", valueText: "Four", value: 4, code: "4CU" },
  33: { uniqueId: 33, suit: "CUPS", valueText: "Five", value: 5, code: "5CU" },
  34: { uniqueId: 34, suit: "CUPS", valueText: "Six", value: 6, code: "6CU" },
  35: { uniqueId: 35, suit: "CUPS", valueText: "Seven", value: 7, code: "7CU" },
  36: { uniqueId: 36, suit: "CUPS", valueText: "Eight", value: 8, code: "8CU" },
  37: { uniqueId: 37, suit: "CUPS", valueText: "Nine", value: 9, code: "9CU" },
  38: { uniqueId: 38, suit: "CUPS", valueText: "Ten", value: 10, code: "10CU" },
  39: {
    uniqueId: 39,
    suit: "CUPS",
    valueText: "Page",
    value: 11,
    code: "11CU",
  },
  40: {
    uniqueId: 40,
    suit: "CUPS",
    valueText: "Knight",
    value: 12,
    code: "12CU",
  },
  41: {
    uniqueId: 41,
    suit: "CUPS",
    valueText: "Queen",
    value: 13,
    code: "13CU",
  },
  42: {
    uniqueId: 42,
    suit: "CUPS",
    valueText: "King",
    value: 14,
    code: "14CU",
  },
  43: { uniqueId: 43, suit: "SWORDS", valueText: "Ace", value: 1, code: "1SW" },
  44: { uniqueId: 44, suit: "SWORDS", valueText: "Two", value: 2, code: "2SW" },
  45: {
    uniqueId: 45,
    suit: "SWORDS",
    valueText: "Three",
    value: 3,
    code: "3SW",
  },
  46: {
    uniqueId: 46,
    suit: "SWORDS",
    valueText: "Four",
    value: 4,
    code: "4SW",
  },
  47: {
    uniqueId: 47,
    suit: "SWORDS",
    valueText: "Five",
    value: 5,
    code: "5SW",
  },
  48: { uniqueId: 48, suit: "SWORDS", valueText: "Six", value: 6, code: "6SW" },
  49: {
    uniqueId: 49,
    suit: "SWORDS",
    valueText: "Seven",
    value: 7,
    code: "7SW",
  },
  50: {
    uniqueId: 50,
    suit: "SWORDS",
    valueText: "Eight",
    value: 8,
    code: "8SW",
  },
  51: {
    uniqueId: 51,
    suit: "SWORDS",
    valueText: "Nine",
    value: 9,
    code: "9SW",
  },
  52: {
    uniqueId: 52,
    suit: "SWORDS",
    valueText: "Ten",
    value: 10,
    code: "10SW",
  },
  53: {
    uniqueId: 53,
    suit: "SWORDS",
    valueText: "Page",
    value: 11,
    code: "11SW",
  },
  54: {
    uniqueId: 54,
    suit: "SWORDS",
    valueText: "Knight",
    value: 12,
    code: "12SW",
  },
  55: {
    uniqueId: 55,
    suit: "SWORDS",
    valueText: "Queen",
    value: 13,
    code: "13SW",
  },
  56: {
    uniqueId: 56,
    suit: "SWORDS",
    valueText: "King",
    value: 14,
    code: "14SW",
  },
  57: {
    uniqueId: 57,
    suit: "MAJOR",
    valueText: "The Fool",
    name: "The Fool",
    value: 0,
    numeral: "0",
    code: "0MAJOR",
  },
  58: {
    uniqueId: 58,
    suit: "MAJOR",
    valueText: "The Magician",
    name: "The Magician",
    value: 1,
    numeral: "I",
    code: "1MAJOR",
  },
  59: {
    uniqueId: 59,
    suit: "MAJOR",
    valueText: "The High Priestess",
    name: "The High Priestess",
    value: 2,
    numeral: "II",
    code: "2MAJOR",
  },
  60: {
    uniqueId: 60,
    suit: "MAJOR",
    valueText: "The Empress",
    name: "The Empress",
    value: 3,
    numeral: "III",
    code: "3MAJOR",
  },
  61: {
    uniqueId: 61,
    suit: "MAJOR",
    valueText: "The Emperor",
    name: "The Emperor",
    value: 4,
    numeral: "IV",
    code: "4MAJOR",
  },
  62: {
    uniqueId: 62,
    suit: "MAJOR",
    valueText: "The Hierophant",
    name: "The Hierophant",
    value: 5,
    numeral: "V",
    code: "5MAJOR",
  },
  63: {
    uniqueId: 63,
    suit: "MAJOR",
    valueText: "The Lovers",
    name: "The Lovers",
    value: 6,
    numeral: "VI",
    code: "6MAJOR",
  },
  64: {
    uniqueId: 64,
    suit: "MAJOR",
    valueText: "The Chariot",
    name: "The Chariot",
    value: 7,
    numeral: "VII",
    code: "7MAJOR",
  },
  65: {
    uniqueId: 65,
    suit: "MAJOR",
    valueText: "Strength",
    name: "Strength",
    value: 8,
    numeral: "VIII",
    code: "8MAJOR",
  },
  66: {
    uniqueId: 66,
    suit: "MAJOR",
    valueText: "The Hermit",
    name: "The Hermit",
    value: 9,
    numeral: "IX",
    code: "9MAJOR",
  },
  67: {
    uniqueId: 67,
    suit: "MAJOR",
    valueText: "Wheel of Fortune",
    name: "Wheel of Fortune",
    value: 10,
    numeral: "X",
    code: "10MAJOR",
  },
  68: {
    uniqueId: 68,
    suit: "MAJOR",
    valueText: "Justice",
    name: "Justice",
    value: 11,
    numeral: "XI",
    code: "11MAJOR",
  },
  69: {
    uniqueId: 69,
    suit: "MAJOR",
    valueText: "The Hanged Man",
    name: "The Hanged Man",
    value: 12,
    numeral: "XII",
    code: "12MAJOR",
  },
  70: {
    uniqueId: 70,
    suit: "MAJOR",
    valueText: "Death",
    name: "Death",
    value: 13,
    numeral: "XIII",
    code: "13MAJOR",
  },
  71: {
    uniqueId: 71,
    suit: "MAJOR",
    valueText: "Temperance",
    name: "Temperance",
    value: 14,
    numeral: "XIV",
    code: "14MAJOR",
  },
  72: {
    uniqueId: 72,
    suit: "MAJOR",
    valueText: "The Devil",
    name: "The Devil",
    value: 15,
    numeral: "XV",
    code: "15MAJOR",
  },
  73: {
    uniqueId: 73,
    suit: "MAJOR",
    valueText: "The Tower",
    name: "The Tower",
    value: 16,
    numeral: "XVI",
    code: "16MAJOR",
  },
  74: {
    uniqueId: 74,
    suit: "MAJOR",
    valueText: "The Star",
    name: "The Star",
    value: 17,
    numeral: "XVII",
    code: "17MAJOR",
  },
  75: {
    uniqueId: 75,
    suit: "MAJOR",
    valueText: "The Moon",
    name: "The Moon",
    value: 18,
    numeral: "XVIII",
    code: "18MAJOR",
  },
  76: {
    uniqueId: 76,
    suit: "MAJOR",
    valueText: "The Sun",
    name: "The Sun",
    value: 19,
    numeral: "XIX",
    code: "19MAJOR",
  },
  77: {
    uniqueId: 77,
    suit: "MAJOR",
    valueText: "Judgement",
    name: "Judgement",
    value: 20,
    numeral: "XX",
    code: "20MAJOR",
  },
  78: {
    uniqueId: 78,
    suit: "MAJOR",
    valueText: "The World",
    name: "The World",
    value: 21,
    numeral: "XXI",
    code: "21MAJOR",
  },
};

export const arrayOfCardIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
];
