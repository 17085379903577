import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "./Account";

const Status = (props) => {
  const [status, setStatus] = useState(false);
  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then((session) => {
        setStatus(true);
      })
      .catch((err) => {
        console.log("get session err", err);
      });
  });

  return (
    <div>
      {status ? (
        <div>
          you are logged in <button onClick={logout}>logout</button>{" "}
        </div>
      ) : (
        "please log in"
      )}
    </div>
  );
};

export default Status;
