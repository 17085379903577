import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import pokerHandReducer from "../features/pokerHands/pokerHandSlice";
import playerReducer from "../features/players/playerSlice";
import deckReducer from "../features/deck/deckSlice";
import gameReducer from "../features/gameState/gameSlice";
import packOpenerReducer from "../features/packOpener/PackOpenerSlice";
import cardViewerReducer from "../features/cardViewer/CardViewerSlice";
import collectionReducer from "../features/collection/collectionSlice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    pokerHand: pokerHandReducer,
    player: playerReducer,
    deck: deckReducer,
    game: gameReducer,
    packOpener: packOpenerReducer,
    cardViewer: cardViewerReducer,
    collection: collectionReducer,
  },
});
