// const { Configuration, OpenAIApi } = require("openai");
// const configuration = new Configuration({
//   //   apiKey: process.env.OPENAI_API_KEY,
//   apiKey: "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",
// });

// const openai = new OpenAIApi(configuration);

// // const response = await openai.createCompletion({
// //   model: "text-davinci-003",
// //   prompt: "Say this is a test",
// //   temperature: 0,
// //   max_tokens: 7,
// // });

// function dalleTest() {
//   const response = openai
//     .createCompletion({
//       model: "text-davinci-003",
//       prompt: "Say this is a test",
//       temperature: 0,
//       max_tokens: 7,
//     })
//     .then((res) => {
//       console.log("new res", res);

//       return res;
//     });
// }

// export { dalleTest };
import axios from "axios";
const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  //   apiKey: process.env.OPENAI_API_KEY,
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);

// const response = await openai.createCompletion({
//   model: "text-davinci-003",
//   prompt: "Say this is a test",
//   temperature: 0,
//   max_tokens: 7,
// });

// function dalleTest() {

//   const response = openai
//     .createImage({
//       prompt: "a gnome with a red hat",
//       n: 1,
//       size: "1024x1024",
//     })
//     .then((res) => {
//       const image_url = res.data.data[0].url;
//       console.log("response:", res.data.data);
//       console.log("URL", image_url);

//       return res.data;
//     });
// }

function dalleTest() {
  console.log("attemp server dalle test");

  const url = "http://localhost:3001/tests3";

  const url2 =
    "http://j3san-Publi-1NQYJ8RQEQPBO-8200804.us-east-1.elb.amazonaws.com/dalle";

  return axios
    .get(
      url2,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("server dalle res", res);
      return res.data;
    })
    .catch((err) => {
      console.log("error contact dalle", err);
    });

  // return response.data;
}

export { dalleTest };
