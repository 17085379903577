import axios from "axios";

// function selectGnomeImage(gnomeId = "1679370912160", gnomeImageNumber) {
function selectGnomeImage(gnomeId, gnomeImageNumber) {
  console.log("selectGnomeImage");

  const url = `http://dev.gnomefight.com/selectimage/${gnomeId}/${gnomeImageNumber}`;

  return axios
    .put(
      url,
      { selectedGnomeImage: gnomeImageNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("selectGnomeImage  res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("selectGnomeImage res", err);
    });
}

export { selectGnomeImage };
