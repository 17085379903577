import { majorArcana, minorArcana, allCards, arrayOfCardIds } from "./cards";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function draw1() {
  const rndInt = randomIntFromInterval(1, 78);
  let result = allCards[rndInt];
  return result;
}

export function drawFullShuffledDeck() {
  function shuffleDeck(deck) {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  }

  const shuffledCardIds = shuffleDeck(arrayOfCardIds);

  let shuffledDeck = [];
  let i = 0;
  for (const id of shuffledCardIds) {
    console.log("card", i, "is:", allCards[id].valueText, allCards[id].suit);
    shuffledDeck.push(allCards[id]);
    i++;
  }

  return shuffledDeck;
}
