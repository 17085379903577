import { Form } from "react-router-dom";

import { Counter } from "../features/counter/Counter";

export default function ReduxTesting() {
  return (
    <div id="contact">
      <div>Redux Testing</div>
      <Counter />
    </div>
  );
}
