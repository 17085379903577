import { createSlice, current } from "@reduxjs/toolkit";
import { drawFullShuffledDeck } from "../../helpers/cardHelpers/deckGenerator";

export const deckSlice = createSlice({
  name: "deck",
  initialState: {
    deck: [],
    topCard: 0,
    // remainingCards: 0,
    players: { 0: { id: 0, hand: [] }, 1: { id: 1, hand: [] } },
  },
  reducers: {
    drawShuffledDeck: (state) => {
      let newDeck = drawFullShuffledDeck();

      console.log("newdeck", newDeck);

      state.topCard = newDeck.length - 1;
      // state.remainingCards = newDeck.length;
      state.deck = newDeck;
    },

    drawFromDeck: (state, action) => {
      // console.log("draw top card", state.deck);
      console.log("draw top card", current(state.deck[state.topCard]));
      state.topCard--;
    },
  },
});

// Action creators are generated for each case reducer function
export const { drawShuffledDeck, drawFromDeck } = deckSlice.actions;

export default deckSlice.reducer;
