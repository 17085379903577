// import SignUpPanel from "../authentication/registerPanel";
// import LoginPanel from "../authentication/loginPanel";

// import React, { useContext, useState, useEffect } from "react";
// import { AccountContext } from "../authentication/Account";

// export default function LoginView() {
//   const [status, setStatus] = useState(false);
//   const { getSession, logout } = useContext(AccountContext);
//   const [register, setRegister] = useState(false);

//   useEffect(() => {
//     getSession()
//       .then((session) => {
//         setStatus(true);
//       })
//       .catch((err) => {
//         // console.log("get session err", err);
//       });
//   });

//   if (status === true) {
//     return (
//       <div>
//         you are logged in <button onClick={logout}>logout</button>
//       </div>
//     );
//   }

//   if (status === false) {
//     return (
//       <div>
//         <div>
//           {register ? (
//             <div>
//               <h2>register </h2> <SignUpPanel />
//               <button
//                 onClick={() => {
//                   setRegister(false);
//                 }}
//               >
//                 return to sign in
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2>login </h2> <LoginPanel />
//               <button
//                 onClick={() => {
//                   setRegister(true);
//                 }}
//               >
//                 or, register new user
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }

//   return <div>error</div>;
// }

// import SignUpPanel from "../authentication/registerPanel";
import AuthenticationContainer from "../authentication/authenticationContainer";

import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../authentication/Account";

export default function LoginView() {
  // const [status, setStatus] = useState(false);
  // const { getSession, logout } = useContext(AccountContext);
  // const [register, setRegister] = useState(false);

  // useEffect(() => {
  //   getSession()
  //     .then((session) => {
  //       setStatus(true);
  //     })
  //     .catch((err) => {
  //       // console.log("get session err", err);
  //     });
  // });

  // if (status === true) {
  //   return (
  //     <div>
  //       you are logged in <button onClick={logout}>logout</button>
  //     </div>
  //   );
  // }

  // if (status === false) {
  //   return (
  //     <div>
  //       <div>
  //         {register ? (
  //           <div>
  //             <h2>register </h2> <SignUpPanel />
  //             <button
  //               onClick={() => {
  //                 setRegister(false);
  //               }}
  //             >
  //               return to sign in
  //             </button>
  //           </div>
  //         ) : (
  //           <div>
  //             <h2>login </h2> <LoginPanel />
  //             <button
  //               onClick={() => {
  //                 setRegister(true);
  //               }}
  //             >
  //               or, register new user
  //             </button>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <AuthenticationContainer />
    </div>
  );
}
