// import { useSelector, useDispatch } from "react-redux";
// import React, { useState, useEffect } from "react";

// import { parseGnomeInitGPT } from "../helpers/gptTesters/parseGPT";

// export default function GameTesting() {
//   const [resText, setResText] = useState("");
//   const [prompt, setPrompt] = useState("");
//   const [parsed, setParsed] = useState([]);

//   const newPrompt = `We are going to invent a collectable card game like hearthstone or magic the gathering. I would like you to please act as a card designer. In this game, Gnomes with powers battle each other.

//   The rules for cards are as follows:

//   Gnome Cards have the following traits.
//   -NAME: Each Gnome has a name referencing what they are.
//   -DESCRIPTION: A description of what the gnome looks like.
//   -FLAVORTEXT: A small bit of text that captures the gnome's theme and motifs in a fantastical way.

//   Can you please, acting as a card designer, design 1 gnome card? Please return just the name and description of the gnome as JSON with the following keys:
//   {name:'',
//   description:'',
//   flavorText:'',}
//   `;

//   const [chatList, setChatList] = []; // ur chat history

//   // const parseIntoArray = (input) => {
//   //   //take a json onj and parse it into an array of objects
//   //   let newArray = [];
//   //   Object.keys(input).forEach(function (key, index) {
//   //     newArray.push(input[key]);
//   //   });
//   //   console.log("newArray", newArray);
//   //   setParsed(newArray);
//   // };

//   // const renderArray = (array) => {
//   //   return array.map((item) => {
//   //     return <li>{item}</li>;
//   //   });
//   // };

//   async function fetchStream(stream) {
//     console.log("fetch STREAM");
//     const reader = stream.getReader();
//     let charsReceived = 0;
//     const li = document.createElement("li");

//     // read() returns a promise that resolves
//     // when a value has been received
//     const result = await reader
//       .read()
//       .then(function processText({ done, value }) {
//         // Result objects contain two properties:
//         // done  - true if the stream has already given you all its data.
//         // value - some data. Always undefined when done is true.
//         if (done) {
//           console.log("Stream complete");
//           return li.innerText;
//         }
//         // value for fetch streams is a Uint8Array
//         charsReceived += value.length;
//         const chunk = value;
//         console.log(
//           `Received ${charsReceived} characters so far. Current chunk = ${chunk}`
//         );
//         li.appendChild(document.createTextNode(chunk));
//         return reader.read().then(processText);
//       });
//     const list = result.split(",");
//     const numList = list.map((item) => {
//       return parseInt(item);
//     });
//     const text = String.fromCharCode(...numList);
//     const response = JSON.parse(text);
//     return response;
//   }

//   async function createCompletion(params = {}) {
//     const DEFAULT_PARAMS = {
//       model: "gpt-3.5-turbo",
//       // messages: [{ role: "user", content: "Hello World" }],
//       messages: [{ role: "user", content: newPrompt }],

//       // max_tokens: 4096,
//       temperature: 0,
//       // frequency_penalty: 1.0,
//       // stream: true,
//     };
//     const params_ = { ...DEFAULT_PARAMS, ...params };
//     const result = await fetch("https://api.openai.com/v1/chat/completions", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization:
//           "Bearer " +
//           String("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"),
//       },
//       body: JSON.stringify(params_),
//     });
//     const stream = result.body;
//     const output = await fetchStream(stream);
//     // setChatList((previousInputs) =>
//     //   previousInputs.concat(output.choices[0].message)
//     // );
//     setResText(output.choices[0].message.content);

//     let final = output.choices[0].message.content;
//     let parsedFinal = parseGnomeInitGPT(final);

//     console.log("json version", parsedFinal);
//     console.log("test", output);

//     // parseIntoArray(parsedFinal);
//   }

//   return (
//     <div id="gametesting">
//       <div>Server Testing</div>
//       {/* <label>
//         Text:
//         <input
//           id="prompt-input"
//           type="text"
//           value={prompt}
//           onChange={(e) => setPrompt(e.target.value)}
//         />
//       </label> */}
//       <button
//         aria-label="gpt request"
//         onClick={() => {
//           createCompletion();
//         }}
//       >
//         gpt test
//       </button>

//       <div>
//         response text:
//         <div>{resText}</div>
//       </div>
//       <div>
//         parsed text:
//         {/* <div>{parsed.map(())}</div> */}
//         {/* <div>{renderArray(parsed)}</div> */}
//       </div>
//     </div>
//   );
// }

import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { parseGnomeInitGPT } from "../helpers/gptTesters/parseGPT";

import {
  postgresAPITest,
  pgtestpost,
} from "../helpers/apiHelpers/auroraHelpers";

export default function GameTesting() {
  const [resText, setResText] = useState("");
  const [prompt, setPrompt] = useState("");
  const [parsed, setParsed] = useState([]);
  const [chatList, setChatList] = []; // ur chat history

  const testPostgresGet = () => {
    console.log("pg test get");
    postgresAPITest();
  };

  const testPostgresPost = () => {
    console.log("ps test post");
    pgtestpost();
  };

  const testEnviromentVar = () => {
    // console.log("test env var", CLIENT_ID);
  };

  return (
    <div id="gametesting">
      <h2>attempt add prod</h2>
      <div>Server Testing</div>

      {/* <button
        aria-label="gpt request"
        onClick={() => {
          createCompletion();
        }}
      >
        gpt test
      </button> */}

      <button
        aria-label="gpt request"
        onClick={() => {
          testPostgresGet();
        }}
      >
        ask server to hit postgres db
      </button>

      <button
        aria-label="gpt request"
        onClick={() => {
          testEnviromentVar();
        }}
      >
        test env var
      </button>

      <button
        aria-label="gpt request"
        onClick={() => {
          testPostgresPost();
        }}
      >
        testPostgresPost
      </button>

      <div>
        response text:
        <div>{resText}</div>
      </div>
      <div>parsed text:</div>
    </div>
  );
}
