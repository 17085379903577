import SignUpPanel from "./registerPanel";
import LoginPanel from "./loginPanel";

import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "./Account";

export default function AuthenticationContainer() {
  const [status, setStatus] = useState(false);
  const { getSession, logout } = useContext(AccountContext);
  const [register, setRegister] = useState(false);

  useEffect(() => {
    getSession()
      .then((session) => {
        setStatus(true);
      })
      .catch((err) => {
        // console.log("get session err", err);
      });
  });

  if (status === true) {
    return (
      <div>
        you are logged in <button onClick={logout}>logout</button>
      </div>
    );
  }

  if (status === false) {
    return (
      <div>
        <div>
          auth container
          {register ? (
            <div>
              <h2>register </h2> <SignUpPanel />
              <button
                onClick={() => {
                  setRegister(false);
                }}
              >
                return to sign in
              </button>
            </div>
          ) : (
            <div>
              <h2>login </h2> <LoginPanel />
              <button
                onClick={() => {
                  setRegister(true);
                }}
              >
                or, register new user
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>error</div>;
}
