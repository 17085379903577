// import React from "react";

// import "./card.css";

// export function Card({ card }) {
//   return (
//     <div className="card-outline">
//       <div>name </div>
//       <div> {card?.name}</div>
//       <div>desc</div>
//       <div> {card?.description}</div>
//       <div>stats</div>
//       <div>pic</div>
//       {/* <img src={card?.s3Image} alt="new" className="card-image" /> */}
//       <img src={card?.mainImage} alt="new" className="card-image" />
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";

import "./card.css";
import { selectGnomeImage } from "../../helpers/apiHelpers/selectGnomeImage";

export function Card({ card }) {
  const [clicked, setClicked] = useState(false);

  const toggleClicked = () => {
    if (clicked === false) setClicked(true);
    if (clicked === true) setClicked(false);
  };

  let image = card.potentialImages;

  // console.log("gnoem selection", card);

  if (clicked === false) {
    return (
      <div className="card-outline-unclicked">
        <div> {card?.name}</div>

        <button
          aria-label="gpt request"
          onClick={(e) => {
            toggleClicked();
          }}
        >
          expand
        </button>
      </div>
    );
  }

  if (clicked === true) {
    return (
      <div className="card-outline">
        <div>name </div>
        <div> {card?.name}</div>
        <div>desc</div>
        <div> {card?.description}</div>
        <div>stats</div>
        <div>pic</div>
        {/* <img src={card?.s3Image} alt="new" className="card-image" /> */}
        <img src={card?.mainImage} alt="new" className="card-image" />
      </div>
    );
  }
}
