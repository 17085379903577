import axios from "axios";

function initGnome(email) {
  console.log("creategnome");

  const url = "http://dev.gnomefight.com/creategnome";

  return axios
    .post(
      url,
      { playerName: email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("creategnome  res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("creategnome res", err);
    });
}

export { initGnome };
