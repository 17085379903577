import axios from "axios";

function generateImagesForGnome(gnomeId = "1679370912160", playerEmail) {
  console.log("generateImagesForGnome");

  const url = `http://dev.gnomefight.com/generateimages/${gnomeId}`;

  return axios
    .put(
      url,
      { playerEmail: playerEmail },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("creategnome  res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("creategnome res", err);
    });
}

export { generateImagesForGnome };
