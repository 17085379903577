import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { drawFromDeck, drawShuffledDeck } from "./gameSlice";
// import styles from "./Counter.module.css";

export function PlayerHand(props) {
  //   const topCard = useSelector((state) => state.deck.topCard);
  const dispatch = useDispatch();

  const player = props.player;

  const playerHand = useSelector((state) => state.game?.players[player]?.hand);

  const cards = playerHand.map((card, i) => {
    return (
      <div id="gametestingframe" key={i}>
        {card.code}
      </div>
    );
  });

  return (
    <div>
      <div>
        <button
          //   aria-label="Increment value"
          //   onClick={() => dispatch(drawFromDeck())}
          onClick={() => {
            console.log("testing player hand", playerHand);
            console.log("TESTING props", props);
          }}
        >
          test player
        </button>
        {/* <button
          aria-label="hide cards"
          //   onClick={() => dispatch(drawFromDeck())}
          onClick={() => {
            console.log("testing player hand", playerHand);
            console.log("TESTING props", props);
          }}
        >
          hide cards
        </button> */}
        player {player + 1}
        <div>{cards}</div>
      </div>
    </div>
  );
}
