// import axios from "axios";

// function dbPostTest() {
//   console.log("dbPostTest");

//   //   const url =
//   //     "http://j3san-Publi-1NQYJ8RQEQPBO-8200804.us-east-1.elb.amazonaws.com/auroradbtest";

//   const url = "http://localhost:8080/auroradbtest";

//   //   const { username, host, dbname, password, port } = JSON.parse(
//   //     process.env.GNOMEPGDB_SECRET
//   //   );

//   //   console.log("username?", username);

//   return axios
//     .get(
//       url,
//       {},
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((res) => {
//       console.log("server db  res", res.data);
//       return res.data;
//     })
//     .catch((err) => {
//       console.log("error db res", err);
//     });
// }

// export { dbPostTest };

import axios from "axios";

function postgresAPITest() {
  console.log("aurora get test");

  // const url = "http://localhost:8080/auroradbtest";

  const url = `http://dev.gnomefight.com/testpostgres`;

  return axios
    .get(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("axios: test post gres res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("axios: error db res", err);
    });
}

function pgtestpost() {
  console.log("aurora get test");

  // const url = "http://localhost:8080/auroradbtest";

  const url = `http://dev.gnomefight.com/pgtestpost`;

  return axios
    .post(
      url,
      { name: "justin haddock", description: "now is the winter" },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("axios: test post gres res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("axios: error db res", err);
    });
}

export { postgresAPITest, pgtestpost };
