import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  drawFromDeck,
  drawShuffledDeck,
  drawHands,
  setUpRound,
  addPlayer,
} from "./gameSlice";
// import styles from "./Counter.module.css";

export function Deck() {
  const topCard = useSelector((state) => state.deck.topCard);
  const dispatch = useDispatch();

  return (
    <div>
      <div>
        <button
          //   aria-label="Increment value"
          onClick={() => dispatch(drawFromDeck())}
        >
          draw from deck
        </button>

        <button
          //   aria-label="Decrement value"
          onClick={() => dispatch(drawShuffledDeck())}
        >
          shuffle new deck
        </button>

        <button
          //   aria-label="Decrement value"
          onClick={() => dispatch(drawHands())}
        >
          draw 1 card for each hand
        </button>

        <button
          //   aria-label="Decrement value"
          onClick={() => dispatch(setUpRound())}
        >
          set up a round
        </button>

        <button
          //   aria-label="Decrement value"
          onClick={() => dispatch(addPlayer())}
        >
          addd player
        </button>

        <span>{topCard}</span>
        {/* <span>{count}</span> */}
      </div>
    </div>
  );
}
