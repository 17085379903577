import { createSlice, current } from "@reduxjs/toolkit";
import { drawFullShuffledDeck } from "../../helpers/cardHelpers/deckGenerator";

export const gameSlice = createSlice({
  name: "game",
  initialState: {
    deck: [],
    topCard: 0,
    // remainingCards: 0,
    players: { 0: { id: 0, hand: [] }, 1: { id: 1, hand: [] } },
    // players: { 0: { id: 0, hand: [] } },
    currentPlayerTurn: 0,
    numberOfPlayers: 1,
  },
  reducers: {
    drawShuffledDeck: (state) => {
      let newDeck = drawFullShuffledDeck();

      console.log("newdeck", newDeck);

      state.topCard = newDeck.length - 1;
      // state.remainingCards = newDeck.length;
      state.deck = newDeck;
    },

    drawFromDeck: (state, action) => {
      // console.log("draw top card", state.deck);
      console.log("draw top card", current(state.deck[state.topCard]));
      state.topCard--;
    },

    drawHands: (state, action) => {
      state.players[0].hand.push(state.deck[state.topCard]);
      state.topCard--;
      state.players[1].hand.push(state.deck[state.topCard]);
      state.topCard--;
      state.players[0].hand.push(state.deck[state.topCard]);
      state.topCard--;
      state.players[1].hand.push(state.deck[state.topCard]);
      state.topCard--;
    },

    addPlayer: (state, action) => {
      //   let x = 1;
      //   state.numberOfPlayers = x;
      //   for (let i = 0; i <= x; i++) {
      //     state.players[i] = { player: i, hand: [] };
      //   }
      state.players[state.numberOfPlayers + 1] = {
        id: state.numberOfPlayers + 1,
        hand: [],
      };
      state.numberOfPlayers++;
      // 1: { id: 1, hand: [] }
    },

    setUpRound: (state) => {
      let newDeck = drawFullShuffledDeck();
      state.topCard = newDeck.length - 1;
      state.deck = newDeck;
      //   state.players[0].hand.push(state.deck[state.topCard]);
      //   state.topCard--;
      //   state.players[1].hand.push(state.deck[state.topCard]);
      //   state.topCard--;
      //   state.players[0].hand.push(state.deck[state.topCard]);
      //   state.topCard--;
      //   state.players[1].hand.push(state.deck[state.topCard]);
      //   state.topCard--;

      for (let i = 0; i <= state.numberOfPlayers; i++) {
        state.players[i].hand.push(state.deck[state.topCard]);
        state.topCard--;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  drawShuffledDeck,
  drawFromDeck,
  drawHands,
  setUpRound,
  addPlayer,
} = gameSlice.actions;

export default gameSlice.reducer;
