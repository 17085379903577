import React, { useState, useEffect } from "react";

import "./card.css";
import { selectGnomeImage } from "../../helpers/apiHelpers/selectGnomeImage";

export function CardImageSelection({ card }) {
  const [clicked, setClicked] = useState(false);

  const toggleClicked = () => {
    if (clicked === false) setClicked(true);
    if (clicked === true) setClicked(false);
  };

  let image = card.potentialImages;

  if (clicked === false) {
    return (
      <div className="card-outline-unclicked">
        <div> {card?.name}</div>

        <button
          aria-label="gpt request"
          onClick={(e) => {
            toggleClicked();
          }}
        >
          expand
        </button>
      </div>
    );
  }

  if (clicked === true) {
    return (
      <div className="card-outline">
        <div>name </div>
        <div> {card?.name}</div>
        <div>desc</div>
        <div> {card?.description}</div>
        <div>stats</div>
        <h2>pics</h2>
        <div className="gnome-pic-options">
          <div>
            <img
              src={card?.potentialImages[0]}
              alt="new"
              className="card-image"
            />
            <button
              aria-label="gpt request"
              onClick={(e) => {
                selectGnomeImage(card.CardID, 0);
              }}
            >
              select image
            </button>
          </div>
          <div>
            <img
              src={card?.potentialImages[1]}
              alt="new"
              className="card-image"
            />
            <button
              aria-label="gpt request"
              onClick={(e) => {
                selectGnomeImage(card.CardID, 1);
              }}
            >
              select image
            </button>
          </div>
          <div>
            <img
              src={card?.potentialImages[2]}
              alt="new"
              className="card-image"
            />
            <button
              aria-label="gpt request"
              onClick={(e) => {
                selectGnomeImage(card.CardID, 2);
              }}
            >
              select image
            </button>
          </div>
        </div>

        <button
          aria-label="gpt request"
          onClick={(e) => {
            toggleClicked();
          }}
        >
          view images
        </button>
      </div>
    );
  }
}
