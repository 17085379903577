import React, { useState, useEffect } from "react";

import "./card.css";
import { selectGnomeImage } from "../../helpers/apiHelpers/selectGnomeImage";

export function StatCard({ card }) {
  const [clicked, setClicked] = useState(false);

  const toggleClicked = () => {
    if (clicked === false) setClicked(true);
    if (clicked === true) setClicked(false);
  };

  let image = card.potentialImages;

  // console.log("gnoem selection", card);

  if (clicked === false) {
    return (
      <div className="card-outline-unclicked">
        <div> {card?.name}</div>

        <button
          aria-label="gpt request"
          onClick={(e) => {
            toggleClicked();
          }}
        >
          expand
        </button>
      </div>
    );
  }

  if (clicked === true) {
    return (
      <div className="card-outline">
        <div>name </div>
        <div> {card?.name}</div>
        <div>desc</div>
        <div> {card?.description}</div>

        <div>pic</div>
        <img src={card?.mainImage} alt="new" className="card-image" />

        <div>
          <div>
            <b>strength: </b>
            {card?.mechanics?.stats?.strength}
          </div>
          <div>
            <b>health: </b>
            {card?.mechanics?.stats?.health}
          </div>
          <div>
            <b>power name: </b>
            {card?.mechanics?.powerName}
          </div>
          <div>
            <b>power description:</b> {card?.mechanics?.powerDescription}
          </div>
          <div>
            <b>trigger: </b>
            {card?.mechanics?.power?.trigger}
          </div>
          <div>
            <b>effect:</b> {card?.mechanics?.power?.effect}
          </div>
          <div>
            <b>target:</b> {card?.mechanics?.power?.target}
          </div>
          <div>
            <b> effect amount:</b> {card?.mechanics?.power?.effectAmount}
          </div>
        </div>
      </div>
    );
  }
}
