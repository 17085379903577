import axios from "axios";

function createStats(gnomeId) {
  console.log("createStats");

  const url = `http://dev.gnomefight.com/generategnomemechanics/${gnomeId}}`;

  return axios
    .put(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log("createStats  res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("createStats res", err);
    });
}

export { createStats };
