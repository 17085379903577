// import logo from "./logo.svg";
import "./App.css";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import {
  ReactQueryDevtools,
  ReactQueryDevtoolsPanel,
} from "react-query/devtools";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>sandbox for testing J3 ideas.</p>
        <p>testing how docker works.</p>
        <p>NEW TEST</p>
      </header>
      <div className="App">
        <header className="App-header">
          <p>new section test.</p>
        </header>
      </div>
    </div>
  );
}

export default App;
