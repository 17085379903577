import axios from "axios";

function fetchAllGnomes() {
  console.log("fetchAllGnomes");

  const url = "http://dev.gnomefight.com/dynfetchallcards";

  return axios.get(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export { fetchAllGnomes };
