import { createSlice } from "@reduxjs/toolkit";

import { draw1 } from "../../helpers/cardHelpers/deckGenerator";

export const pokerHandSlice = createSlice({
  name: "pokerHand",
  initialState: {
    cardsInHand: [],
  },
  reducers: {
    addCardToHand: (state, action) => {
      state.cardsInHand.push(draw1());
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCardToHand } = pokerHandSlice.actions;

export default pokerHandSlice.reducer;
