import { Form } from "react-router-dom";

export default function MainMenu() {
  return (
    <div id="contact">
      <div>MAIN MENU</div>
      <div>menu</div>
    </div>
  );
}
