import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./routes/errorPage";
import Contact from "./routes/contact";
import MainMenu from "./routes/mainMenu";
import GameTesting from "./routes/gameTesting";
import ReduxTesting from "./routes/reduxTesting";
import ServerTesting from "./routes/serverTesting";
import CardShop from "./routes/cardShop";
import CollectionView from "./routes/collectionView";
import LoginView from "./routes/loginView";
import { Account } from "./authentication/Account";
import Status from "./authentication/Status";

import store from "./app/store";
import { Provider } from "react-redux";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./routes/root";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import {
  ReactQueryDevtools,
  ReactQueryDevtoolsPanel,
} from "react-query/devtools";

// Create a client
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "mainmenu",
        element: <MainMenu />,
      },
      {
        path: "gametesting",
        element: <GameTesting />,
      },
      {
        path: "reduxtesting",
        element: <ReduxTesting />,
      },
      {
        path: "servertesting",
        element: <ServerTesting />,
      },
      {
        path: "cardshop",
        element: <CardShop />,
      },
      {
        path: "collection",
        element: <CollectionView />,
      },
      {
        path: "login",
        element: <LoginView />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <Account>
      <Provider store={store}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </Provider>
      <ReactQueryDevtools initialIsOpen={true} />
      <Status />
    </Account>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
